strong, b {
	font-weight: 300;
}
.DateInput {
  width: 110px;
}
.DateInput_input {
	font-family: "Gotham", Helvetica, Arial, "sans-serif";
	font-weight: 300;
	color: #333953;
	font: 300 14px "Gotham", Helvetica, Arial, "sans-serif";
}
.DateInput_input__focused {
	border-bottom: 2px solid #333953;
}
.PresetDateRangePicker_button {
	font-weight: 300;
	color: #7222CD;
	border-color: #7222CD;
}
.PresetDateRangePicker_button__selected {
	background: #7222CD;
	color: #FFF;
}
.DayPickerKeyboardShortcuts_show__bottomRight{
	display: none;
	border-top: 26px solid #0000;
  border-right: 33px solid #3B82F6;
}
.CalendarMonth_caption {
	color: #333953;
}
.DayPicker_weekHeader {
	color: #7F8095;
}
.CalendarDay__default {
	color: #333953;
}
.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
	background: #3B82F6;
	border: 1px solid #3B82F6;
	color: #fff;
	opacity: .6;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
	background: #3B82F6;
	border-color: #3B82F6;
}
.CalendarDay__selected_span{
	background: #3B82F6;
	border: 1px solid #3B82F6;
	color: #fff;
	opacity: .6;
}
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
	background: #3B82F6;
	border-color: #3B82F6;
	opacity: .8;
}
.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
	color: #E5E5E5;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
	display: none;
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
	right: -27px;
}

@media only screen and (max-width: 700px) {
	.DateRangePicker_picker {
		z-index: 100;
	}
	.DayPicker_weekHeader_1:nth-child(2) {
		display: none
	}
	.DayPicker {
		width: calc(100vw - 68px) !important;
	}
	.DayPicker > div,
	.DayPicker > div > div,
	.DayPicker_transitionContainer,
	.CalendarMonth_table {
	  width: 100% !important;
	}
	.CalendarMonthGrid {
	  width: 1000% !important;
	}
	.CalendarMonthGrid_month__horizontal {
	  width: calc(10% - 18px) !important;
	}
	.DayPicker_weekHeader {
	  width: calc(100% - 9px) !important;
	}
	.CalendarDay,
	.DayPicker_weekHeader_li {
	  width: 14.285714286% !important;
	}
}

@media only screen and (max-width: 555px) {
	.PresetDateRangePicker_button_1:nth-child(4) {
		margin-top: 10px;
	}
}